import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import PageHeadSection from '../components/PageHeadSection';
import Section from '../components/Section';
import SEO from '../components/SEO';

function ListingsPage(props) {
  useEffect(() => {
    const interval = setInterval(() => {
      if (window.BuildOut && window.BuildOut.embed) {
        window.BuildOut.embed({
          token: '185f6aa77f788c2986918962c70dfbf80a1935fe',
          plugin: 'inventory',
          target: 'buildout',
        });
        clearInterval(interval);
      }
    }, 50);
    return () => clearInterval(interval);
  }, []);

  return (
    <Layout>
      <SEO title="Listings" />
      <PageHeadSection title="Listings" />
      <Section
        color="white"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        data-aos="fade-up"
      >
        <div id="buildout" />
      </Section>
    </Layout>
  );
}

export default ListingsPage;
